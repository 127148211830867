body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: url("./assets/home-bg1.jpg") no-repeat fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  color:white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.logo{
  font-family: 'Open Sans','Helvetica Neue',Helvetica,Arial,sans-serif;
  color: #61dafb !important;
  padding: 20px;
  font-size: 12px !important;
  font-weight: 800;
  letter-spacing: 1px;
}

.nav-item a {
  font-family: 'Open Sans','Helvetica Neue',Helvetica,Arial,sans-serif;
  color:  #e83e8c !important;
  padding: 5px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 800;
  letter-spacing: 1px;
  text-align: center;
}

.nav-item a:hover {
	font-family: 'Open Sans','Helvetica Neue',Helvetica,Arial,sans-serif;
  color: rgba(232,62,140,.9) !important;
}

.social:hover {
     -webkit-transform: scale(1.1);
     -moz-transform: scale(1.1);
     -o-transform: scale(1.1);
 }
 .social {
     -webkit-transform: scale(0.8);
     /* Browser Variations: */
     
     -moz-transform: scale(0.8);
     -o-transform: scale(0.8);
     -webkit-transition-duration: 0.5s;
     -moz-transition-duration: 0.5s;
     -o-transition-duration: 0.5s;
     color: #61dafb !important;
}

footer{
  background:#0e0e0e !important;  
  color:#a1a1a1;
  font-size: calc(10px + 1vmin);
}

.home, .about{
  color:white;
}

.about span{
  color:white !important;
  padding-top:50px;
  font-size:23px;
  font-weight: 700;
  text-transform: uppercase;
}

.about{
  .profile{
      border-radius: 50%;
      max-height:300px;
      border:7px solid black;
  }
  .motto{
      color:#222;
      font-size: calc(12px + 1vmin);
      font-weight: 800;
      text-transform: uppercase;
  }
}

.ib-card{
	/*padding: 0px 30px;*/
    line-height: 30px;
    background: rgba(0, 0, 0, 0.4);
    color: #fff;
    display: inline-block;
    border-radius: 1px;
   /* border: 1px solid #7a8186;*/
    font-size: 10px;
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    -webkit-transition: all 300ms linear 0s;
    -o-transition: all 300ms linear 0s;
    transition: all 300ms linear 0s;
    display:block;
    /*height:150px;*/
    text-align: center;
    text-transform: uppercase;
    -webkit-box-shadow: 0px 8px 12px 1px rgba(0,0,0, 0.49);
    padding:5px;
}

.ib-card a{
  height: 75px;
  display: block;
}

.ib-card-footer{
	text-align:center;
	background:rgba(0, 0, 0, 0.25);
	color:white;
}

.intro-header .site-heading, .intro-header .post-heading, .intro-header .page-heading {
    padding: 50px 0;
}

/* Sticky footer styles
-------------------------------------------------- */
html {
  position: relative;
  min-height: 100%;
}
body {
  margin-bottom: 120px; /* Margin bottom by footer height */
}
.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 120px; /* Set the fixed height of the footer here */
 /* line-height: 60px;*/ /* Vertically center the text there */
  background-color: #f5f5f5;
}


/* Custom page CSS
-------------------------------------------------- */
/* Not required for template or sticky footer method. */


footer .list-inline {
  margin: 0;
  padding: 0;
}
footer .copyright {
  font-size: 14px;
  text-align: center;
  margin-bottom: 0;
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-weight: 700;
}

.intro-header .site-heading, .intro-header .post-heading, .intro-header .page-heading {
    padding: 100px 0 50px;
    color: white;
}

.intro-header .site-heading, .intro-header .page-heading {
    text-align: center;
}

@media only screen and (min-width: 768px) {
  .intro-header .site-heading h1,
  .intro-header .page-heading h1 {
    font-size: 50px;
  }
}

hr.small {
    max-width: 100px;
    margin: 15px auto;
    border-width: 4px;
    border-color: #fff;
}

.small, small {
    font-size: 85%;
}

h2{
	    color:#1e2235;
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.navbar-toggler {
  border-color: rgba(255,255,255,0.5);
} 

.fixed-top{
	/*background: rgba(1,1,1,1);*/
	background:#0e0e0e !important;
  text-align: center;
}

.ib-galery-card{
	/* border: 1px solid #7a8186;
	 background: rgba(255, 255, 255, 0.3);*/
	 margin-right: 0px;
	 margin-left: 0px;
}

.img-galery{
	/*max-height:200px;*/
	 width: 100%;
	 height: 300px;
	 object-fit: cover;
	 padding:10px;
	 overflow: hidden;
}